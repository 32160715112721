<template>
  <div class="_gallery__grid">
    <picture
      :class="'_picture--' + item.id"
      v-for="item in gallery"
      :key="item.id"
    >
      <img loading="lazy" class="_image" :src="item.path" alt="Grid Gallery" />
    </picture>
  </div>
</template>

<script>
export default {
  name: "GridGallery",
  props: ["gallery"],
  mounted() {
    // console.log("gallery", this.gallery);
  },
};
</script>

<style scoped lang="scss">
._gallery__grid {
  height: 450px;
  //display: grid;
  // grid-template-rows: 1fr 0.5fr 0.5fr;
  // grid-template-columns: 2fr 0.75fr 1.25fr 2fr;
  gap: 5px;
  // // background-color: $creme;
  ._picture {
    &--1 {
      @extend ._picture;
      //grid-area: 1 / 1 / 2 / 2;
      flex: 1 0 30%;
      height: 50%;
    }
    &--2 {
      @extend ._picture;
      //grid-area: 1 / 2 / 2 / 4;
      flex: 1 0 30%;
      height: 50%;
    }
    &--3 {
      @extend ._picture;
      //grid-area: 1 / 4 / 2 / 5;
      flex: 1 0 30%;
      height: 50%;
    }
    &--4 {
      @extend ._picture;
      //grid-area: 2 / 1 / 3 / 3;
      flex: 1 0 30%;
      height: 50%;
    }
    &--5 {
      @extend ._picture;
      //grid-area: 3 / 1 / 4 / 3;
      flex: 1 0 30%;
      height: 50%;
    }
    &--6 {
      @extend ._picture;
      //grid-area: 2 / 3 / 4 / 5;
      flex: 1 0 30%;
      height: 50%;
    }
  }
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 30%));
  grid-template-rows: auto auto;

  display: flex;
  flex-wrap: wrap;
}

@media screen and(min-width: 600px) {
  ._gallery__grid {
    height: 550px;
  }
}

@media screen and(min-width: 1200px) {
  ._gallery__grid {
    height: 650px;
  }
}
</style>