<template>
  <div class="_slider-slot" ref="sliderSlot">
    <div class="_slider_content" ref="sliderContent">
      <div class="_content__wrapper" ref="sliderContentWrapper">
        <slot>
          <!-- Content will be rendered here -->
        </slot>
      </div>
    </div>
    <div class="_navigation">
      <button class="_nav-btn__left" @click="gotoItem(-1)">&lt;</button>
      <button class="_nav-btn__right" @click="gotoItem(1)">&gt;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentSlider",

  data: () => {
    return {
      currentItem: 0,
      items: [],
      itemWidth: 0,
    };
  },
  methods: {
    initSliderPanel() {
      // console.log("initSliderPanel");
      //const sliderSlot = this.$refs.sliderSlot;
      const sliderContent = this.$refs.sliderContent;
      const sliderContentWrapper = this.$refs.sliderContentWrapper;
      sliderContentWrapper.style.width = `${sliderContent.scrollWidth}px`;
      this.items = sliderContentWrapper.getElementsByClassName("_slider__item");
      this.itemWidth = this.items[0].offsetWidth;
      this.items.forEach((element) => {
        element.style.flexBasis = this.itemWidth;
      });
      sliderContent.scrollLeft = 0;
      console.log(
        "initSliderPanel",
        `items: ${this.items.length}`,
        `itemWidth: ${this.itemWidth}`,
        `sliderContent.scrollWidth: ${sliderContent.scrollWidth}`,
        `sliderContentWrapper.scrollWidth: ${sliderContentWrapper.scrollWidth}`
      );
    },
    gotoItem(direction) {
      const sliderContent = this.$refs.sliderContent;
      this.currentItem += direction;
      if (direction < 0 && this.currentItem < 0) {
        this.currentItem = this.items.length - 1;
      } else if (direction > 0 && this.currentItem > this.items.length - 1) {
        this.currentItem = 0;
      }
      let scrollX = this.currentItem * this.itemWidth;
      sliderContent.scrollLeft = scrollX;
      console.log(
        `currentItem: ${this.currentItem}`,
        `sliderContent.scrollLeft: ${sliderContent.scrollLeft} > ${scrollX}`
      );
    },
    onResize() {
      this.initSliderPanel();
    },
    onContentScroll(e) {
      const sliderContent = this.$refs.sliderContent;
      const snapBuffer = (sliderContent.offsetWidth - this.itemWidth) / 2;
      this.currentItem = Math.round(
        (e.target.scrollLeft + snapBuffer) / (this.itemWidth + 30)
      );
      // console.log(
      //   `currentItem: ${this.currentItem}`,
      //   `snapBuffer: ${snapBuffer}`,
      //   `scrollLeft: ${e.target.scrollLeft}`,
      //   `center: ${e.target.scrollLeft + snapBuffer + this.itemWidth / 2}`
      // );
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.$refs.sliderContent.addEventListener("scroll", this.onContentScroll);
    this.initSliderPanel();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.$refs.sliderContent.removeEventListener(
      "scroll",
      this.onContentScroll
    );
  },
};
</script>

<style scoped lang="scss">
._slider-slot {
  // border: 5px solid red;
  max-height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto;
  align-items: center;
  overflow: hidden;

  ._slider_content {
    // border: 2px solid blue;
    height: inherit;
    max-height: inherit;
    grid-area: 1/1/2/2;
    display: flex;
    overflow: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    // hide scrollbars
    &::-webkit-scrollbar {
      display: none;
    }

    ._content__wrapper {
      // border: 2px solid green;
      height: inherit;
      max-height: inherit;
      display: flex;
      gap: 30px;
      ._slider__item {
        flex: 0 0 80%;
        scroll-snap-align: center;
      }
    }
  }
  ._navigation {
    grid-area: 1/1/2/2;
    display: none;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    pointer-events: none;
    ._nav-btn {
      @extend ._text;
      padding: 20px;
      color: white;
      background-color: rgba($color: #000000, $alpha: 0.5);
      border: none;
      pointer-events: auto;
      cursor: pointer;
      font-size: 3rem;
      &__left {
        @extend ._nav-btn;
      }
      &__right {
        @extend ._nav-btn;
      }
      &:hover {
        border: 1px solid white;
      }
    }
  }
}
@media screen and (max-width: 400px) {
  ._slider-slot {
    ._content__wrapper {
      ._slider__item {
        flex: 0 0 50%;
      }
    }
  }
}
@media screen and (min-width: 1000px) {
  ._slider-slot {
    ._slider_content {
      ._content__wrapper {
        ._slider__item {
          flex: 0 0 60%;
        }
      }
    }
    ._navigation {
      display: flex;
    }
  }
}
</style>